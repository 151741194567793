import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Пропозиції автопрокату VividI
			</title>
			<meta name={"description"} content={"Здійснюйте свої мрії з нашим різноманітним автопарком!"} />
			<meta property={"og:title"} content={"Про нас | Пропозиції автопрокату VividI"} />
			<meta property={"og:description"} content={"Здійснюйте свої мрії з нашим різноманітним автопарком!"} />
			<meta property={"og:image"} content={"https://vividionget.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vividionget.com/img/5846979.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vividionget.com/img/5846979.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vividionget.com/img/5846979.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vividionget.com/img/5846979.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vividionget.com/img/5846979.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vividionget.com/img/5846979.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 30px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="50%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="normal 900 62px/1.2 --fontFamily-sans"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Що може запропонувати VividI
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Відкрийте для себе широкий спектр послуг, пропонованих компанією VividI Car Rentals, розроблених для задоволення потреб кожного мандрівника. Незалежно від того, чи потрібен вам автомобіль для бізнесу, відпочинку або для особливих випадків, наш великий автопарк і гнучкі плани оренди гарантують, що ви знайдете саме те, що вам потрібно, і саме тоді, коли вам це потрібно.
				</Text>
				<Link
					href="/contact-us"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Зв'язатися з нами
				</Link>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="50%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Наш автопарк
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Автомобілі економ-класу: Ідеально підходять для міських мандрівників, які прагнуть ефективності та легкості.
						<br/>
						Паливна економічність: Ідеально підходить для тривалих поїздок без частих зупинок для заправки.
						<br/>
						Компактний дизайн: Легко орієнтуйтеся в дорожньому русі та на обмеженому паркувальному місці.
						<br/>
						Позашляховики та мінівени: відмінний вибір для сімей та груп, які потребують більше простору та потужності.
						<br/>
						Просторі салони: Зручно розмістіть багаж, туристичне спорядження або спортивне обладнання.
						<br/>
    Удосконалені функції безпеки: Оснащені новітніми технологіями безпеки для захисту всіх пасажирів.
						<br/>
Автомобілі класу люкс: Для тих випадків, коли вам потрібна нотка елегантності та неперевершеного комфорту.
						<br/>
    Преміум-функції: Шкіряний салон, клімат-контроль та сучасні мультимедійні системи.
						<br/>
    Моделі з відкидним верхом: Ідеальні для мальовничих поїздок уздовж узбережжя або через сонячні пейзажі.
						<br/>
    Позашляхові можливості: Впевнено долайте будь-яку місцевість з нашими надійними варіантами 4х4.

						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Індивідуальні рішення щодо оренди
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Гнучкі варіанти бронювання: Погодинна, добова, тижнева або місячна оренда відповідно до вашого графіку.
						<br/>
Персоналізовані пакети: Доповнення, такі як GPS-навігація, дитячі крісла або додаткове страхове покриття для вашої зручності.
						<br/>
Бізнес-оренда: Спеціальні ціни та послуги для корпоративних клієнтів, включаючи оренду автопарку та довгострокову оренду.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Дізнайтеся більше з VividI
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Цей огляд лише зачіпає поверхню того, що може запропонувати компанія VividI Car Rentals. Щоб ознайомитися з повним спектром наших послуг і знайти ідеальний варіант для вашої наступної подорожі, зв'яжіться з нами або відвідайте наш центр прокату. Наша команда готова допомогти вам у створенні ідеального пакету оренди.
				</Text>
			</Box>
			<Box
				display="grid"
				width="100%"
				margin="80px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				sm-grid-template-columns="1fr"
			>
				<Image
					src="https://vividionget.com/img/3.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="1 / span 1"
					border-radius="24px"
				/>
				<Image
					src="https://vividionget.com/img/4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="2 / span 2"
					border-radius="24px"
					sm-grid-column="1 / span 1"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});